import React, { Component } from 'react';
import omit from 'lodash/omit';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { array, arrayOf, bool, func, object, shape, string } from 'prop-types';

import { propTypes } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import { useIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import {
  isOriginInUse,
  isAnyFilterActive,
  getQueryParamNames,
  isMainSearchTypeKeywords,
} from '../../util/search';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';

import { H3, H5, Page, ModalInMobile } from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';

import { setActiveListing } from './SearchPage.duck';
import {
  initialValues,
  validFilterParams,
  searchParamsPicker,
  pickListingFieldFilters,
  createSearchResultSchema,
  groupListingFieldConfigs,
  validUrlQueryParamsFromProps,
  omitLimitedListingFieldParams,
  cleanSearchFromConflictingParams,
} from './SearchPage.shared';

import SortBy from './SortBy/SortBy';
import SearchMap from './SearchMap/SearchMap';
import FilterComponent from './FilterComponent';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import NoSearchResultsMaybe from './NoSearchResultsMaybe/NoSearchResultsMaybe';
import SearchFiltersPrimary from './SearchFiltersPrimary/SearchFiltersPrimary';
import SearchFiltersSecondary from './SearchFiltersSecondary/SearchFiltersSecondary';
import SearchResultsPanelCustom from './SearchResultsPanelCustom/SearchResultsPanelCustom';
import TopbarSearchForm from '../TopbarContainer/Topbar/TopbarSearchForm/TopbarSearchForm';

import css from './SearchPage.module.css';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: false,
      isMobileModalOpen: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      isSecondaryFiltersOpen: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

    // Filter functions
    this.resetAll = this.resetAll.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = this.props.routeConfiguration;
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, config } = this.props;
      const { listingFields: listingFieldsConfig } = config?.listing || {};
      const { defaultFilters: defaultFiltersConfig } = config?.search || {};
      const listingCategories = config.categoryConfiguration.categories;
      const filterConfigs = {
        listingFieldsConfig,
        defaultFiltersConfig,
        listingCategories,
      };

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      const originMaybe = isOriginInUse(this.props.config) ? { origin: viewportCenter } : {};
      const dropNonFilterParams = false;

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, filterConfigs, dropNonFilterParams),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, routeConfiguration, config } = this.props;
    const listingCategories = config.categoryConfiguration.categories;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const filterConfigs = {
      listingCategories,
      listingFieldsConfig,
      defaultFiltersConfig,
    };

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(searchParams, filterConfigs, sortConfig);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = getQueryParamNames(listingFieldsConfig, defaultFiltersConfig);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);

    // Forcefully resetting sort, keywords and location (radius, bounds & address)
    delete queryParams['sort'];
    delete queryParams['radius'];
    delete queryParams['bounds'];
    delete queryParams['address'];
    delete queryParams['keywords'];

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, routeConfiguration, config } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const listingCategories = config.categoryConfiguration.categories;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const filterConfigs = {
      listingFieldsConfig,
      defaultFiltersConfig,
      listingCategories,
    };

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        // const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        const keywordsMaybe = keywords ? { keywords } : {};

        return {
          currentQueryParams: omitLimitedListingFieldParams(
            {
              address,
              bounds,
              ...mergedQueryParams,
              ...updatedURLParams,
              ...keywordsMaybe,
            },
            filterConfigs
          ),
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const currentSearchParams = new URLSearchParams(location.search);

          // Updating both 'address' & 'bounds' params to match the current value from URL...
          if (searchParams['address'] !== currentSearchParams.get('address')) {
            searchParams['radius'] = currentSearchParams.get('radius');
            searchParams['bounds'] = currentSearchParams.get('bounds');
            searchParams['address'] = currentSearchParams.get('address');
          }

          // Updating the 'keywords' param to match the current search keywords from URL...
          searchParams['keywords'] = currentSearchParams.get('keywords');
          const search = cleanSearchFromConflictingParams(searchParams, filterConfigs, sortConfig);

          history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, routeConfiguration } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  handleSubmit(values) {
    const { currentSearchParams } = this.props;
    const { location, keywords, ...rest } = values || {};
    const { history, config, routeConfiguration } = this.props;

    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords };
      }

      // topbar search defaults to 'location' search
      const { search, selectedPlace } = location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        bounds,
        address: search,
        ...originMaybe,
        keywords,
        ...rest,
      };
    };

    const searchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  }

  render() {
    const {
      intl,
      config,
      history,
      location,
      scrollingDisabled,
      routeConfiguration,
      listings,
      pagination,
      currentUser,
      searchParams,
      searchInProgress,
      searchListingsError,
      activeListingId,
      onActivateListing,
      onManageDisableScrolling,
      isAuthenticated,
    } = this.props;
    const { mobilemenu, mobilesearch, keywords, address } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const marketplaceCurrency = config.currency;
    const { listingFields } = config?.listing || {};
    const categoryConfiguration = config.categoryConfiguration;
    const listingCategories = categoryConfiguration.categories;
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
    const activeListingTypes = config?.listing?.listingTypes.map(config => config.listingType);

    const listingFieldsConfig = pickListingFieldFilters({
      listingFields,
      locationSearch: location.search,
      categoryConfiguration,
    });

    const filterConfigs = {
      listingFieldsConfig,
      defaultFiltersConfig,
      listingCategories,
    };

    // Page transition might initially use values from previous search
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
    const { searchParamsAreInSync, urlQueryParams, searchParamsInURL } = searchParamsPicker(
      location.search,
      searchParams,
      filterConfigs,
      sortConfig,
      isOriginInUse(config)
    );

    const validQueryParams = urlQueryParams;
    const { bounds, origin } = searchParamsInURL || {};
    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const builtInPrimaryFilters = defaultFiltersConfig.filter(f =>
      ['categoryLevel'].includes(f.key)
    );

    const builtInFilters = isKeywordSearch
      ? defaultFiltersConfig.filter(f => !['keywords', 'categoryLevel'].includes(f.key))
      : defaultFiltersConfig.filter(f => !['categoryLevel'].includes(f.key));

    const [customPrimaryFilters, customSecondaryFilters] = groupListingFieldConfigs(
      listingFieldsConfig,
      activeListingTypes
    );

    const availableFilters = [
      ...builtInPrimaryFilters,
      ...customPrimaryFilters,
      ...builtInFilters,
      ...customSecondaryFilters,
    ];

    availableFilters.reverse();
    delete availableFilters[availableFilters.length - 1];
    [availableFilters[1], availableFilters[3]] = [availableFilters[3], availableFilters[1]];
    // [availableFilters[2], availableFilters[3]] = [availableFilters[3], availableFilters[2]];

    const availablePrimaryFilters = [
      ...builtInPrimaryFilters,
      ...customPrimaryFilters,
      ...builtInFilters,
    ];

    availablePrimaryFilters.reverse();
    delete availablePrimaryFilters[availablePrimaryFilters.length - 1];
    [availablePrimaryFilters[1], availablePrimaryFilters[2]] = [
      availablePrimaryFilters[2],
      availablePrimaryFilters[1],
    ];
    [availablePrimaryFilters[2], availablePrimaryFilters[3]] = [
      availablePrimaryFilters[3],
      availablePrimaryFilters[2],
    ];

    // Selected aka active filters
    const selectedFilters = validQueryParams;
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;
    const isValidDatesFilter =
      searchParamsInURL.dates == null ||
      (searchParamsInURL.dates != null && searchParamsInURL.dates === selectedFilters.dates);

    // Selected aka active secondary filters
    const hasSecondaryFilters = !!(customSecondaryFilters && customSecondaryFilters.length > 0);
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(validQueryParams, {
          listingFieldsConfig: customSecondaryFilters,
          defaultFiltersConfig: [],
          listingCategories,
        })
      : {};
    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length;

    const isSecondaryFiltersOpen = !!hasSecondaryFilters && this.state.isSecondaryFiltersOpen;
    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
          isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
          toggleSecondaryFiltersOpen: isOpen => {
            this.setState({ isSecondaryFiltersOpen: isOpen, currentQueryParams: {} });
          },
          selectedSecondaryFiltersCount,
        }
      : {};

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalFetchedItems = pagination?.totalItems;
    const totalItems = listings?.length;
    // const totalItems =
    //   searchParamsAreInSync && hasPaginationInfo
    //     ? pagination.totalItems
    //     : pagination?.paginationUnsupported
    //     ? listings.length
    //     : 0;

    const listingsAreLoaded =
      !searchInProgress &&
      searchParamsAreInSync &&
      !!(hasPaginationInfo || pagination?.paginationUnsupported);

    const conflictingFilterActive = isAnyFilterActive(
      sortConfig.conflictingFilters,
      validQueryParams,
      filterConfigs
    );

    const topbarSearcInitialValues = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords };
      }

      // Only render current search if full place object is available in the URL params
      const locationFieldsPresent = isOriginInUse(config)
        ? address && origin && bounds
        : address && bounds;
      return {
        keywords,
        location: locationFieldsPresent
          ? {
              search: address,
              selectedPlace: { address, origin, bounds },
            }
          : null,
      };
    };
    const initialSearchFormValues = topbarSearcInitialValues();

    const sortBy = mode => {
      return sortConfig.active ? (
        <SortBy
          mode={mode}
          showAsPopup
          onSelect={this.handleSortBy}
          selectedFilters={selectedFilters}
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
          sort={validQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
        />
      ) : null;
    };

    const noResultsInfo = (
      <NoSearchResultsMaybe
        location={location}
        totalItems={totalItems}
        resetAll={this.resetAll}
        listingsAreLoaded={listingsAreLoaded}
      />
    );

    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchParamsInURL || {},
      intl,
      routeConfiguration,
      config
    );

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    return (
      <Page
        title={title}
        schema={schema}
        description={description}
        scrollingDisabled={scrollingDisabled}
      >
        <TopbarContainer
          rootClassName={topbarClasses}
          showRequirementsButton={true}
          currentSearchParams={validQueryParams}
        />

        <div className={classNames(css.container, css.responsiveContainer)} role="main">
          <div className={css.searchResultContainer}>
            <div className={css.searchContainer}>
              <TopbarSearchForm
                className={css.searchLink}
                isMobile
                history={history}
                appConfig={config}
                routes={routeConfiguration}
                onSubmit={this.handleSubmit}
                initialValues={initialSearchFormValues}
              />
            </div>

            <SearchFiltersMobile
              className={css.searchFiltersMobileMap}
              urlQueryParams={validQueryParams}
              sortByComponent={sortBy('mobile')}
              listingsAreLoaded={listingsAreLoaded}
              resultsCount={totalItems}
              totalFetchedItems={totalFetchedItems}
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              showAsModalMaxWidth={MODAL_BREAKPOINT}
              onMapIconClick={() => this.setState({ isSearchMapOpenOnMobile: true })}
              onManageDisableScrolling={onManageDisableScrolling}
              onOpenModal={this.onOpenMobileModal}
              onCloseModal={this.onCloseMobileModal}
              resetAll={this.resetAll}
              selectedFiltersCount={selectedFiltersCountForMobile}
              noResultsInfo={noResultsInfo}
              isMapVariant
              currentUser={currentUser}
              isAuthenticated={isAuthenticated}
            >
              {availableFilters.map(filterConfig => {
                const key = `SearchFiltersMobile.${filterConfig.scope || 'built-in'}.${
                  filterConfig.key
                }`;

                return (
                  <FilterComponent
                    key={key}
                    idPrefix="SearchFiltersMobile"
                    config={filterConfig}
                    listingCategories={listingCategories}
                    marketplaceCurrency={marketplaceCurrency}
                    urlQueryParams={validQueryParams}
                    initialValues={initialValues(this.props, this.state.currentQueryParams)}
                    getHandleChangedValueFn={this.getHandleChangedValueFn}
                    intl={intl}
                    liveEdit
                    showAsPopup={false}
                  />
                );
              })}
            </SearchFiltersMobile>

            <MainPanelHeader
              resultsCount={totalItems}
              noResultsInfo={noResultsInfo}
              isSortByActive={sortConfig.active}
              className={css.mainPanelMapVariant}
              sortByComponent={sortBy('desktop')}
              searchInProgress={searchInProgress}
              listingsAreLoaded={listingsAreLoaded}
              totalFetchedItems={totalFetchedItems}
              searchListingsError={searchListingsError}
            >
              <SearchFiltersPrimary
                location={location}
                resetAll={this.resetAll}
                selectedFiltersCountForMobile={selectedFiltersCountForMobile}
                {...propsForSecondaryFiltersToggle}
              >
                {availablePrimaryFilters.map(filterConfig => {
                  const key = `SearchFiltersPrimary.${filterConfig.scope || 'built-in'}.${
                    filterConfig.key
                  }`;

                  return (
                    <FilterComponent
                      key={key}
                      intl={intl}
                      showAsPopup
                      config={filterConfig}
                      idPrefix="SearchFiltersPrimary"
                      urlQueryParams={validQueryParams}
                      listingCategories={listingCategories}
                      marketplaceCurrency={marketplaceCurrency}
                      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      initialValues={initialValues(this.props, this.state.currentQueryParams)}
                    />
                  );
                })}
              </SearchFiltersPrimary>
            </MainPanelHeader>

            <div className={css.divider} />

            {isSecondaryFiltersOpen ? (
              <div className={classNames(css.searchFiltersPanel)}>
                <SearchFiltersSecondary
                  urlQueryParams={validQueryParams}
                  listingsAreLoaded={listingsAreLoaded}
                  applyFilters={this.applyFilters}
                  cancelFilters={this.cancelFilters}
                  resetAll={this.resetAll}
                  onClosePanel={() => this.setState({ isSecondaryFiltersOpen: false })}
                >
                  {customSecondaryFilters.map(filterConfig => {
                    const key = `SearchFiltersSecondary.${filterConfig.scope || 'built-in'}.${
                      filterConfig.key
                    }`;

                    return (
                      <FilterComponent
                        key={key}
                        idPrefix="SearchFiltersSecondary"
                        config={filterConfig}
                        listingCategories={listingCategories}
                        marketplaceCurrency={marketplaceCurrency}
                        urlQueryParams={validQueryParams}
                        initialValues={initialValues(this.props, this.state.currentQueryParams)}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        intl={intl}
                        showAsPopup={false}
                      />
                    );
                  })}
                </SearchFiltersSecondary>
              </div>
            ) : (
              <div
                className={classNames(css.listingsForGridVariant, {
                  [css.newSearchInProgress]: !(listingsAreLoaded || searchListingsError),
                })}
              >
                {searchListingsError ? (
                  <H3 className={css.error}>
                    <FormattedMessage id="SearchPage.searchError" />
                  </H3>
                ) : null}
                {!isValidDatesFilter ? (
                  <H5>
                    <FormattedMessage id="SearchPage.invalidDatesFilter" />
                  </H5>
                ) : null}
                <SearchResultsPanelCustom
                  listings={listings}
                  isMapVariant={false}
                  currentUser={currentUser}
                  search={parse(location.search)}
                  className={css.searchListingsPanel}
                  pagination={listingsAreLoaded ? pagination : null}
                  hasNoResult={listingsAreLoaded && totalItems === 0}
                  onManageDisableScrolling={onManageDisableScrolling}
                  isNewSearchInProgress={!(listingsAreLoaded || searchListingsError)}
                />
              </div>
            )}
          </div>

          {/* <ModalInMobile
            className={css.mapPanel}
            id="SearchPage.map"
            isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
            onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.mapWrapper} data-testid="searchMapContainer">
              {shouldShowSearchMap ? (
                <SearchMap
                  reusableContainerClassName={css.map}
                  activeListingId={activeListingId}
                  bounds={bounds}
                  center={origin}
                  isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                  location={location}
                  listings={listings || []}
                  onMapMoveEnd={this.onMapMoveEnd}
                  onCloseAsModal={() => {
                    onManageDisableScrolling('SearchPage.map', false);
                  }}
                  messages={intl.messages}
                />
              ) : null}
            </div>
          </ModalInMobile> */}
        </div>

        <FooterContainer />
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <SearchPageComponent
      routeConfiguration={routeConfiguration}
      location={location}
      history={history}
      config={config}
      intl={intl}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.auth;
  const {
    pagination,
    searchParams,
    activeListingId,
    currentPageResultIds,
    searchInProgress,
    searchListingsError,
  } = state.SearchPage;

  const listings = getListingsById(state, currentPageResultIds);
  // const listingsExceptRfqs = listings?.filter(listing => !listing?.attributes?.publicData?.isRfq);

  return {
    listings,
    pagination,
    currentUser,
    searchParams,
    activeListingId,
    isAuthenticated,
    searchInProgress,
    searchListingsError,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(connect(mapStateToProps, mapDispatchToProps))(EnhancedSearchPage);

export default SearchPage;
