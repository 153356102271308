import React, { Component, useEffect } from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';

import { intlShape, injectIntl } from '../../../../util/reactIntl';
import { isMainSearchTypeKeywords } from '../../../../util/search';
import { createResourceLocatorString } from '../../../../util/routes';

import { Form, FieldTextInput, LocationAutocompleteInput } from '../../../../components';

import IconSearchDesktop from './IconSearchDesktop';

import css from './TopbarSearchForm.module.css';
import { ReactComponent as XMarkSVG } from '../../../../assets/svg/x-mark.svg';
import { ReactComponent as SearchSVG } from '../../../../assets/svg/search.svg';
import { ReactComponent as SearchWhiteSVG } from '../../../../assets/svg/search-white.svg';
import { ReactComponent as LocationGraySVG } from '../../../../assets/svg/locatin-gray.svg';

const identity = v => v;

const KeywordSearchField = props => {
  const { keywordSearchWrapperClasses, iconClass, intl, isMobile, inputRef } = props;
  return (
    <div className={keywordSearchWrapperClasses}>
      <button className={css.searchSubmit}>
        <div className={iconClass}>
          <IconSearchDesktop />
        </div>
      </button>
      <Field
        name="keywords"
        render={({ input, meta }) => {
          return (
            <input
              className={isMobile ? css.mobileInput : css.desktopInput}
              {...input}
              id={isMobile ? 'keyword-search-mobile' : 'keyword-search'}
              data-testid={isMobile ? 'keyword-search-mobile' : 'keyword-search'}
              ref={inputRef}
              type="text"
              placeholder={intl.formatMessage({
                id: 'TopbarSearchForm.placeholder',
              })}
              autoComplete="off"
            />
          );
        }}
      />
    </div>
  );
};

const LocationSearchField = props => {
  const { desktopInputRootClass, intl, isMobile, inputRef, onLocationChange } = props;
  return (
    <Field
      name="location"
      format={identity}
      render={({ input, meta }) => {
        const { onChange, ...restInput } = input;

        // Merge the standard onChange function with custom behaviur. A better solution would
        // be to use the FormSpy component from Final Form and pass this.onChange to the
        // onChange prop but that breaks due to insufficient subscription handling.
        // See: https://github.com/final-form/react-final-form/issues/159
        const searchOnChange = value => {
          onChange(value);
          onLocationChange(value);
        };

        return (
          <LocationAutocompleteInput
            className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
            iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
            inputClassName={isMobile ? css.mobileInput : css.desktopInput}
            predictionsClassName={isMobile ? css.mobilePredictions : css.desktopPredictions}
            predictionsAttributionClassName={isMobile ? css.mobilePredictionsAttribution : null}
            placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
            closeOnBlur={!isMobile}
            inputRef={inputRef}
            input={{ ...restInput, onChange: searchOnChange }}
            meta={meta}
          />
        );
      }}
    />
  );
};

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    // onChange is used for location search
    this.onChange = this.onChange.bind(this);

    // onSubmit is used for keywords search
    // this.onSubmit = this.onSubmit.bind(this);

    // Callback ref
    this.searchInput = null;
    // this.setSearchInputRef = element => {
    //   this.setSearchInput = element;
    // };
  }

  // onChange(location) {
  //   const { appConfig, onSubmit } = this.props;
  //   if (!isMainSearchTypeKeywords(appConfig) && location.selectedPlace) {
  //     // Note that we use `onSubmit` instead of the conventional
  //     // `handleSubmit` prop for submitting. We want to autosubmit
  //     // when a place is selected, and don't require any extra
  //     // validations for the form.
  //     onSubmit({ location });
  //     // blur search input to hide software keyboard
  //     this.searchInput?.blur();
  //   }
  // }

  onChange(location, currentSearchParams) {
    const newSearchParams = {};

    Array.from(currentSearchParams.entries()).forEach(entry => {
      newSearchParams[entry[0]] = entry[1];
    });

    if (location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      delete newSearchParams.bounds;
      delete newSearchParams.address;
      delete newSearchParams.page; // Forcefully removed to prevent inconsistent paginated results
      this.props.onSubmit({ location, ...newSearchParams });

      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput?.blur();
      }
    }
  }

  onSubmit(values) {
    const { appConfig, onSubmit } = this.props;

    if (isMainSearchTypeKeywords(appConfig)) {
      onSubmit({ keywords: values.keywords });

      // blur search input to hide software keyboard
      this.searchInput?.blur();
    }
  }

  render() {
    const { routes, onSubmit, appConfig, location, ...restOfProps } = this.props;
    // const isKeywordsSearch = isMainSearchTypeKeywords(appConfig);
    // const submit = isKeywordsSearch ? this.onSubmit : onSubmit;

    return (
      <FinalForm
        {...this.props}
        // onSubmit={submit}
        render={formRenderProps => {
          const {
            intl,
            form,
            values,
            history,
            isMobile,
            className,
            rootClassName,
            desktopInputRoot,
            // handleSubmit,
          } = formRenderProps;
          const currentSearchParams = new URLSearchParams(location.search);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;
          const classes = classNames(rootClassName, className, css.formClassName);

          // // Location search: allow form submit only when the place has changed
          // const preventFormSubmit = e => e.preventDefault();
          // const submitFormFn = isKeywordsSearch ? handleSubmit : preventFormSubmit;

          // const keywordSearchWrapperClasses = classNames(
          //   css.keywordSearchWrapper,
          //   isMobile ? css.mobileInputRoot : desktopInputRootClass
          // );

          // Location search: allow form submit only when the place has changed
          const preventFormSubmit = (e, checkKeywords = true) => {
            e.preventDefault();
            const newSearchParams = {};
            const { ne, sw } = values?.location?.selectedPlace?.bounds || {};

            const latLngBounds = () => {
              const neLatLng = [];
              const swLatLng = [];
              neLatLng.push(ne?.lat, ne?.lng);
              swLatLng.push(sw?.lat, sw?.lng);
              const boundsLatLng = [...neLatLng, ...swLatLng];

              // %2C is the encoded form of ','
              // 35.673315%2C97.395359%2C6.6718373%2C68.1152344
              // boundsLatLng = '35.673315,97.395359,6.6718373,68.1152344'
              return ne && sw ? boundsLatLng : null;
            };

            Array.from(currentSearchParams.entries()).forEach(entry => {
              newSearchParams[entry[0]] = entry[1];
            });

            if (checkKeywords && values?.keywords) {
              newSearchParams.keywords = values.keywords;
              delete newSearchParams.page; // Forcefully removed to prevent inconsistent paginated results
            } else {
              newSearchParams.keywords = null;
            }

            if (values?.location?.search !== 'Near Me') {
              if (values?.location?.search) {
                newSearchParams.bounds = latLngBounds()?.join(',');
                newSearchParams.address = values?.location?.selectedPlace?.address;
                delete newSearchParams.page; // Forcefully removed to prevent inconsistent paginated results
              } else {
                newSearchParams.radius = null;
                newSearchParams.bounds = null;
                newSearchParams.address = null;
              }
            }

            history.push(createResourceLocatorString('SearchPage', routes, {}, newSearchParams));
          };

          const resetKeywords = e => {
            form.change('keywords', null);

            if (currentSearchParams.get('keywords')) {
              values.keywords = null;
              preventFormSubmit(e);
            }
          };

          const resetLocation = e => {
            const checkKeywords = currentSearchParams.get('keywords');
            const addressValue = currentSearchParams.get('address');
            form.change('location.search', '');

            if (typeof addressValue === 'string') {
              values.location.search = '';
              preventFormSubmit(e, checkKeywords);
            }
          };

          useEffect(() => {
            const addressValue = currentSearchParams.get('address');

            if (typeof addressValue === 'string' && addressValue === '') {
              form.change('location.search', 'Near Me');
            } else if (addressValue === null) {
              form.change('location.search', '');
            }
          }, [location.search]);

          return (
            <Form
              className={classes}
              onSubmit={preventFormSubmit}
              enforcePagePreloadFor="SearchPage"
            >
              {/* <div className={css.searchInputDiv}> */}
              {/* <div className={css.hDivider}></div> */}
              <Field
                name="location"
                format={identity}
                render={({ input, meta }) => {
                  const { onChange, ...restInput } = input;

                  // Merge the standard onChange function with custom behaviur. A better solution would
                  // be to use the FormSpy component from Final Form and pass this.onChange to the
                  // onChange prop but that breaks due to insufficient subscription handling.
                  // See: https://github.com/final-form/react-final-form/issues/159
                  const searchOnChange = value => {
                    onChange(value);
                    this.onChange(value, currentSearchParams);
                  };

                  const searchInput = {
                    ...restInput,
                    onChange: searchOnChange,
                  };

                  return (
                    <div className={css.locationSearchBox}>
                      <LocationGraySVG />

                      <LocationAutocompleteInput
                        meta={meta}
                        isOpen={true}
                        input={searchInput}
                        closeOnBlur={true}
                        // closeOnBlur={!isMobile}
                        iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                        inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                        className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
                        predictionsClassName={
                          isMobile ? css.mobilePredictions : css.desktopPredictions
                        }
                        predictionsAttributionClassName={
                          isMobile ? css.mobilePredictionsAttribution : null
                        }
                        inputRef={node => {
                          this.searchInput = node;
                        }}
                        placeholder={intl.formatMessage({
                          id: 'TopbarSearchForm.locationPlaceholder',
                        })}
                      />

                      {values?.location?.search && (
                        <XMarkSVG onClick={resetLocation} className={css.xMarkSVG} />
                      )}
                    </div>
                  );
                }}
              />

              <div className={css.parentKeywordSearchBox}>
                <div className={css.keywordSearchBox}>
                  <SearchSVG />

                  <FieldTextInput
                    type="text"
                    name="keywords"
                    className={css.keywordInput}
                    placeholder={intl.formatMessage({
                      id: 'TopbarSearchForm.keywordsPlaceholder',
                    })}
                  />

                  {values?.keywords && (
                    <XMarkSVG onClick={resetKeywords} className={css.xMarkSVG} />
                  )}
                </div>

                <button className={css.searchBtn}>
                  <SearchWhiteSVG />
                </button>
              </div>
            </Form>

            // <Form className={classes} onSubmit={submitFormFn} enforcePagePreloadFor="SearchPage">
            //   {isKeywordsSearch ? (
            //     <KeywordSearchField
            //       keywordSearchWrapperClasses={keywordSearchWrapperClasses}
            //       iconClass={classNames(isMobile ? css.mobileIcon : css.desktopIcon || css.icon)}
            //       intl={intl}
            //       isMobile={isMobile}
            //       inputRef={this.setSearchInputRef}
            //     />
            //   ) : (
            //     <LocationSearchField
            //       desktopInputRootClass={desktopInputRootClass}
            //       intl={intl}
            //       isMobile={isMobile}
            //       inputRef={this.setSearchInputRef}
            //       onLocationChange={this.onChange}
            //     />
            //   )}
            // </Form>
          );
        }}
      />
    );
  }
}

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,
  appConfig: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = compose(withRouter, injectIntl)(TopbarSearchFormComponent);

export default TopbarSearchForm;
