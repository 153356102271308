import React from 'react';
import classNames from 'classnames';
import { bool, func, number, string } from 'prop-types';

import { FormattedMessage } from '../../../util/reactIntl';

import { SecondaryButton } from '../../../components';

import PopupOpenerButton from '../PopupOpenerButton/PopupOpenerButton';

import css from './SearchFiltersPrimary.module.css';

const SearchFiltersPrimaryComponent = props => {
  const {
    children,
    location,
    resetAll,
    className,
    rootClassName,
    isSecondaryFiltersOpen,
    toggleSecondaryFiltersOpen,
    selectedFiltersCountForMobile,
    selectedSecondaryFiltersCount,
  } = props;
  // const hasSearchParams = location.search?.length > 0;
  const classes = classNames(rootClassName || css.root, className);

  const toggleSecondaryFiltersOpenButton = toggleSecondaryFiltersOpen ? (
    <PopupOpenerButton
      isSelected={isSecondaryFiltersOpen || selectedSecondaryFiltersCount > 0}
      toggleOpen={() => {
        toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
      }}
    >
      <FormattedMessage
        id="SearchFiltersPrimary.moreFiltersButton"
        values={{ count: selectedSecondaryFiltersCount }}
      />
    </PopupOpenerButton>
  ) : null;

  return (
    <div className={classes}>
      <div className={css.filters}>
        {children}
        {toggleSecondaryFiltersOpenButton}
      </div>

      {selectedFiltersCountForMobile > 0 && (
        <div className={css.buttonsWrapper}>
          <SecondaryButton className={css.resetButton} onClick={e => resetAll(e)}>
            <FormattedMessage id={'SearchPage.resetAllFilters'} />
          </SecondaryButton>
        </div>
      )}
    </div>
  );
};

SearchFiltersPrimaryComponent.defaultProps = {
  rootClassName: null,
  className: null,
  isSecondaryFiltersOpen: false,
  toggleSecondaryFiltersOpen: null,
  selectedSecondaryFiltersCount: 0,
};

SearchFiltersPrimaryComponent.propTypes = {
  rootClassName: string,
  className: string,
  isSecondaryFiltersOpen: bool,
  toggleSecondaryFiltersOpen: func,
  selectedSecondaryFiltersCount: number,
};

const SearchFiltersPrimary = SearchFiltersPrimaryComponent;

export default SearchFiltersPrimary;
